<template>
  <div>
    <b-card title="İzin Talebi">
      <permit-request-card :data-item="dataItem" />
    </b-card>
    <div
      class="text-center"
    >
      <b-button
        v-if="dataItem.id_com_user_manager === userData.id"
        variant="success"
        class="text-center mr-1"

        :disabled="dataItem.id_com_permit_request_status !== '1'"
        @click="submitData(1)"
      >
        <FeatherIcon icon="CheckCircleIcon" />
        Onayla

      </b-button>

      <b-button
        v-if="dataItem.id_com_user_manager === userData.id"
        variant="danger"
        class="text-center"
        :disabled="dataItem.id_com_permit_request_status !== '1'"
        @click="submitData(2)"
      >
        <FeatherIcon icon="XCircleIcon" />
        Reddet

      </b-button>
      <b-button
        v-if="userData.id_com_user_type === '11' || userData.id_com_user_type === '1'"
        variant="success"
        class="text-center ml-1"
        :disabled="userData.id_com_user_type !== '11' && userData.id_com_user_type !== '1' || dataItem.id_com_permit_request_status !== '2'"
        @click="submitData(3)"
      >
        <FeatherIcon icon="CheckCircleIcon" />
        Talebi Sonlandır

      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import PermitRequestCard from '@/views/Permit_request/elements/PermitRequestCard.vue'

export default {
  name: 'PermitRequestEdit',
  components: {
    PermitRequestCard,
    BButton,
    BCard,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
    saveDataStatus() {
      return this.$store.getters['permitRequest/saveDataStatus']
    },
  },
  watch: {
    saveDataStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getData()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('permitRequest/dataItem', this.$route.params.id)
    },
    submitData(type) {
      if (this.dataItem) {
        if (type) {
          if (type === 1) {
            // onayla
            this.dataItem.id_com_permit_request_status = '2'
            this.dataItem.adate = this.moment().format('YYYY-MM-DD')
          } else if (type === 2) {
            // reddet
            this.dataItem.id_com_permit_request_status = '3'
          } else if (type === 3) {
            // Tamamla
            this.dataItem.id_com_permit_request_status = '4'
          }
          this.$store.dispatch('permitRequest/saveData', this.dataItem)
        }
      } else {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'İşlem Hatası',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
